// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --lvl1-light: #238a9f;\n  --lvl1-dark: #104f5b;\n  --lvl2-light: #67ebd8;\n  --lvl2-dark: #3faa9a;\n  --lvl3-light: #9ecc96;\n  --lvl3-dark: #6fa066;\n  --lvl4-light: #a166d0;\n  --lvl4-dark: #673093;\n}\n\n.btn-primary {\n  background-color: var(--lvl4-dark) !important;\n  border-color: var(--lvl4-dark) !important;\n}\n\n._1h1fztc5lW5ZHjDNUjht_R {\n  background-color: white;\n  height: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1h1fztc5lW5ZHjDNUjht_R"
};
module.exports = exports;
