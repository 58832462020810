// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3C5iYDiGluus06MaLBTMpB {\n  /* these fix the background coloring */\n  margin: auto -16px;\n  padding: 8px 32px;\n  background-color: var(--lvl2-dark);\n  color: white;\n}\n\n._2sWq23B58SGHzdUipKAl3g a {\n  color: white !important;\n  width: 8em;\n  border-radius: 12px;\n}\n\n.T0dMtSAxJtM7vm-TMdFLa {\n  color: white;\n  font-family: \"Roboto\", Arial, Helvetica, sans-serif;\n  letter-spacing: 4px;\n}\n\n._2GVv1EZ1TVgwg15e8ic1Z8 {\n  color: var(--lvl1-dark);\n  font-weight: 800;\n}\n", ""]);
// Exports
exports.locals = {
	"navbar": "_3C5iYDiGluus06MaLBTMpB",
	"navLinks": "_2sWq23B58SGHzdUipKAl3g",
	"titleMandala": "T0dMtSAxJtM7vm-TMdFLa",
	"titleGlobalHealth": "_2GVv1EZ1TVgwg15e8ic1Z8"
};
module.exports = exports;
