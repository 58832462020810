import React from "react";
import { cx, cy } from "./constants";

interface RingProps {
  tags: Array<Tag>;
  r: number;
}

export default function RingLabels({ r, tags }: RingProps) {
  return (
    <g>
      {tags.map((t, i) =>
        t.ringName ? (
          <>
            <RingLabel r={r + 0.2} i={i} text={t.ringName[0]} />
            <RingLabel r={Math.pow(r, 0.98) - 0.2} i={i} text={t.ringName[1]} />
          </>
        ) : (
          <RingLabel r={Math.pow(r, 0.99)} i={i} text={t.name} />
        )
      )}
    </g>
  );
}

interface RingLabelProps {
  r: number;
  i: number;
  text: string;
}

function RingLabel({ r, i, text }: RingLabelProps) {
  return (
    <g key={`label-${text}`}>
      <path
        id={`textcurve-${text}`}
        fill="transparent"
        // we draw an arc using the parametric equation of a circle
        d={
          // move to the center to start
          `m ${cx} ${cy}` +
          // move to start position
          `m ${r * cos(i * 60 - 120)}, ${r * sin(i * 60 - 120)}` +
          // draw an arc to the endpoint. Coordinates of the endpoint are provided relative to the start point
          `a ${r},${r} 0 0,1 ` +
          `${r * (cos(i * 60 - 60) - cos(i * 60 - 120))},` + // x
          `${r * (sin(i * 60 - 60) - sin(i * 60 - 120))}` // y
        }
      />
      <text
        fontSize={`${Math.pow(r, 0.2) / 2.5}px`}
        fill="white"
        textAnchor="middle"
        pointerEvents="none"
        style={{ userSelect: "none" }}
      >
        <textPath xlinkHref={`#textcurve-${text}`} startOffset="50%">
          {text}
        </textPath>
      </text>
    </g>
  );
}

function sin(angleDegrees: number) {
  return Math.sin((angleDegrees * Math.PI) / 180);
}

function cos(angleDegrees: number) {
  return Math.cos((angleDegrees * Math.PI) / 180);
}
