import { getFirestore, doc, getDoc } from "firebase/firestore/lite";
import { useEffect, useState } from "react";

export function useCopyText(title: string): string {
  const [text, setText] = useState();

  useEffect(() => {
    const db = getFirestore();
    const copyRef = doc(db, "copy-text", title);
    getDoc(copyRef).then(snapshot => {
      setText(snapshot.data().text);
    });
  }, []);

  return text;
}
