import React, { useRef, useState, useEffect } from "react";
import { useDrag } from "react-use-gesture";
import { cx, cy } from "./constants";
import RingLabels from "./RingLabels";
import { useSpring, animated } from "@react-spring/web";

const strokeWidth = 2.6;
const gap = 0.4;

interface RingProps {
  tags: Array<Tag>;
  r: number;
  color: string;
  handleSelectTag: (t: Tag) => void;
}

export default function Ring({ tags, r, color, handleSelectTag }: RingProps) {
  const [angleAtDragStart, setAngleAtDragStart] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [{ angle }, setAngle] = useSpring(() => ({ angle: 0 }));
  const c = 2 * Math.PI * r;
  const gRef = useRef(null);

  const dragBind = useDrag(({ initial, xy, first, last, vxvy: [vx, vy] }) => {
    if (first) {
      setAngleAtDragStart(angle.get());
      setIsSpinning(true);
    }
    const boundingBox = gRef.current?.getBoundingClientRect();
    const cxPx = boundingBox ? boundingBox.left + 0.5 * boundingBox.width : 0;
    const cyPx = boundingBox ? boundingBox.top + 0.5 * boundingBox.height : 0;
    const [x0, y0] = [initial[0] - cxPx, initial[1] - cyPx];
    const [x1, y1] = [xy[0] - cxPx, xy[1] - cyPx];
    const radialMove = -(Math.atan2(y0, x0) - Math.atan2(y1, x1)) * 57;
    const newAngle = angleAtDragStart + radialMove;
    if (last) {
      const radialVelocity =
        (Math.atan2(y1, x1) - Math.atan2(y1 - vy, x1 - vx)) * 57;
      const endingAngle =
        Math.round((newAngle + radialVelocity * 500) / 60) * 60;
      setAngle({
        angle: endingAngle,
        immediate: false,
        config: { velocity: radialVelocity, tension: 80 },
        onRest: () => {
          setIsSpinning(false);
        }
      });
      const tagIndex = Math.floor(((390 - (endingAngle % 360)) / 60) % 6);
      handleSelectTag(tags[tagIndex]);
    } else {
      setAngle({ angle: newAngle, immediate: true });
    }
  });

  return (
    <animated.g
      transform={angle.to(a => `rotate(${a} ${cx} ${cy})`)}
      {...dragBind()}
      ref={gRef}
    >
      <circle
        className="donut-hole"
        cx={cx}
        cy={cy}
        r={r}
        fill="white"
      ></circle>
      <circle
        className="donut-ring"
        cx={cx}
        cy={cy}
        r={r}
        fill="transparent"
        stroke="white"
        strokeWidth={strokeWidth}
      ></circle>
      {tags.map((t, i) => (
        <circle
          key={`circle-${t.id}`}
          className="donut-segment"
          cx={cx}
          cy={cy}
          r={r}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          // The stroke-dasharray is a string with two space-delimited values, like "10 50"
          // The first value is the length of the stroke, which is one sixth of the ring,
          // minus whatever the gap is
          // The second value is the length of the remainder of the ring
          strokeDasharray={`${c / 6 - gap} ${(5 / 6) * c + gap}`}
          strokeDashoffset={c - (i * c) / 6 + c / 3 - gap / 2}
        ></circle>
      ))}
      <RingLabels tags={tags} r={r} />
    </animated.g>
  );
}
