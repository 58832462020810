import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import LessonPlanPicker from "../lesson-plan-picker/LessonPlanPicker";
import { useCopyText } from "../useCopyText";
import Wheel from "../wheel/Wheel";

export default function Home() {
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);
  const [stateBustingWheelKey, setStateBustingWheelKey] = useState(12340987);
  const topBlurb = useCopyText("top-blurb");

  return (
    <>
      <div
        className="text-center my-3"
        dangerouslySetInnerHTML={{ __html: topBlurb }}
      />
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Wheel setSelectedTags={setSelectedTags} key={stateBustingWheelKey} />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            color="primary"
            onClick={() => {
              setStateBustingWheelKey(k => k + 1);
              setSelectedTags([])
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <LessonPlanPicker selectedTags={selectedTags} />
    </>
  );
}
