import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import style from "./TopNav.css";

export default function TopNav() {
  return (
    <Navbar light expand="md" className={style.navbar}>
      <NavLink tag={Link} to="/" className="navbar-brand mr-auto">
        <div className={style.titleMandala}>MANDALA</div>
        <div className={style.titleGlobalHealth}>Global Health</div>
      </NavLink>
      <Nav navbar className={`flex-row ${style.navLinks}`}>
        <NavItem>
          <NavLink tag={Link} to="/about" className="btn btn-primary">
            About
          </NavLink>
        </NavItem>
        <NavItem className="ml-4">
          <NavLink tag={Link} to="/browse" className="btn btn-primary">
            Browse
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}
