import React, { useContext } from "react";
import { cx, cy } from "./constants";
import { Text } from "@visx/text";
import { useHistory } from "react-router-dom";
import { LessonPlansContext } from "../context";

interface MiddleButtonProps {
  r: number;
}

export default function MiddleButton({ r }: MiddleButtonProps) {
  const lessonPlans = useContext(LessonPlansContext);
  const history = useHistory();

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill="#104F5B"
        onClick={() => {
          const lp =
            lessonPlans[Math.floor(Math.random() * lessonPlans.length)];
          history.push(`/lesson-plan/${lp.id}`);
        }}
      />
      <Text
        x={cx}
        y={cy + 0.7}
        fontSize={0.6}
        fill="white"
        width={5}
        textAnchor="middle"
        pointerEvents="none"
      >
        I'm feeling lucky
      </Text>
    </g>
  );
}
