export const tags = [
  { id: 11, name: "Effective", level: 1 },
  { id: 12, name: "Efficient", level: 1 },
  {
    id: 13,
    name: "Availability/Timely",
    level: 1,
    ringName: ["Availability", "/Timely"]
  },
  {
    id: 14,
    name: "Equitable/Affordable",
    level: 1,
    ringName: ["Equitable", "/Affordable"]
  },
  {
    id: 15,
    name: "Patient-centered",
    level: 1,
    ringName: ["Patient-", "centered"]
  },
  { id: 16, name: "Safe", level: 1 },
  { id: 21, name: "Health Services", level: 2 },
  { id: 22, name: "Health Information", level: 2 },
  { id: 23, name: "Health Workforce", level: 2 },
  { id: 24, name: "Health Financing", level: 2 },
  { id: 25, name: "Health Products", level: 2 },
  {
    id: 26,
    name: "Leadership & Governance",
    level: 2,
    ringName: ["Leadership &", "Governance"]
  },
  {
    id: 31,
    name: "Social Stratification/Inequality",
    level: 3,
    ringName: ["Social Stratification", "/Inequality"]
  },
  { id: 32, name: "Exposures", level: 3 },
  { id: 33, name: "Disorganization", level: 3 },
  { id: 34, name: "Human Rights", level: 3 },
  { id: 35, name: "Vulnerability", level: 3 },
  {
    id: 36,
    name: '"Big Booms" Unequal Consequences',
    level: 3,
    ringName: ['"Big Booms"', "Unequal Consequences"]
  },
  { id: 41, name: "Mental Health", level: 4 },
  { id: 42, name: "Trauma", level: 4 },
  { id: 43, name: "Non-Communicable Diseases", level: 4 },
  { id: 44, name: "Maternal/Child Health", level: 4 },
  { id: 45, name: "Acute Infection Diseases", level: 4 },
  { id: 46, name: "Substance Abuse", level: 4 }
];

export const tagsByLevel = tags.reduce(
  (acc, value) => {
    acc[value.level - 1].push(value);
    return acc;
  },
  [[], [], [], []]
);
