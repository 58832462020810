import React from "react";
import ReactDOM from "react-dom";
import { initializeApp } from "firebase/app";
import { pdfjs } from 'react-pdf';
import App from "./App";

import "!style-loader!css-loader!bootstrap/dist/css/bootstrap.css";
import "!style-loader!css-loader!./global.css";

// Initialize Cloud Firestore through Firebase
initializeApp({
  apiKey: "AIzaSyDHHSruAB0GWy2JqCQ6S1pb2BH96ZqOg2I",
  authDomain: "global-health-lesson-plans.firebaseapp.com",
  databaseURL: "https://global-health-lesson-plans.firebaseio.com",
  projectId: "global-health-lesson-plans",
  storageBucket: "global-health-lesson-plans.appspot.com",
  messagingSenderId: "714586336107",
  appId: "1:714586336107:web:c69c9c97e79ecf6bdcba2e",
  measurementId: "G-J9GKY4K0CW"
});

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

ReactDOM.render(<App />, document.getElementById("root"));
