// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3yBWWoyOXe_v9VSWAT68pZ {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}", ""]);
// Exports
exports.locals = {
	"wheelContainer": "_3yBWWoyOXe_v9VSWAT68pZ"
};
module.exports = exports;
