import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { range } from "lodash";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";
import { RouteComponentProps } from "react-router-dom";

type RouteParams = {
  slug: string;
};

export default function LessonPlan({
  match
}: RouteComponentProps<RouteParams>) {
  const { slug } = match.params;
  const [numPages, setNumPages] = useState(0);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    let isMounted = true;
    const db = getFirestore();
    const lessonPlanRef = doc(db, "lesson-plans", slug);
    getDoc(lessonPlanRef).then(docSnapshot => {
      setFileUrl(docSnapshot.data().file.src);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {fileUrl && (
        <Document
          file={fileUrl}
          onLoadSuccess={pdf => setNumPages(pdf.numPages)}
        >
          {numPages > 0 &&
            range(1, numPages).map(pageNum => (
              <Page pageNumber={pageNum} key={`lesson-plan-p-${pageNum}`} />
            ))}
        </Document>
      )}
    </>
  );
}
