import React, { useState } from "react";
import { Row, Col, Input, Label, FormGroup } from "reactstrap";
import LessonPlanPicker from "../lesson-plan-picker/LessonPlanPicker";
import { tagsByLevel } from "../tags";

export default function Browse() {
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);

  return (
    <>
      <Row>
        {tagsByLevel.map((tags, colNum) => (
          <Col key={`checkbox-column-${colNum}`}>
            {tags.map(tag => (
              <FormGroup key={`checkbox-${tag.id}`} check className="my-2">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedTags(selected => selected.concat([tag]));
                      } else {
                        setSelectedTags(selected =>
                          selected.filter(s => s != tag)
                        );
                      }
                    }}
                  />
                  <span className="ml-2">{tag.name}</span>
                </Label>
              </FormGroup>
            ))}
          </Col>
        ))}
      </Row>
      <LessonPlanPicker selectedTags={selectedTags} />
    </>
  );
}
