import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader
} from "reactstrap";
import { tags } from "../tags";
import style from "./LessonPlanPicker.css";
import { LessonPlansContext } from "../context";

interface LessonPlanPickerProps {
  selectedTags: Tag[];
}

export default function LessonPlanPicker({
  selectedTags
}: LessonPlanPickerProps) {
  const allLessonPlans = useContext(LessonPlansContext);
  
  const visibleLessonPlans = useMemo(() => {
    // If a tag is selected, then all articles in the list should either
    // have that tag or have no tags for that level.
    return allLessonPlans.filter(lp =>
      selectedTags.every(
        selectedTag =>
          lp.tags.includes(selectedTag.id) ||
          noTagsForLevel(lp.tags, selectedTag.level)
      )
    ).sort((lp1, lp2) => {
      const matchingTagsCount = (lp: LessonPlan) => lp.tags.filter(t => selectedTags.map(st => st.id).includes(t)).length;
      return  matchingTagsCount(lp2) - matchingTagsCount(lp1);
    });
  }, [allLessonPlans, selectedTags]);

  return (
    <Card>
      <CardHeader className={style.listHeader}>
        {selectedTags.length
          ? selectedTags.map(t => t.name).join(" + ")
          : "Lesson Plans"}
      </CardHeader>
      <CardBody className={style.listBody}>
        {visibleLessonPlans.map(lp => (
          <a href={`lesson-plan/${lp.id}`} key={lp.id}>
            <Card>
              <CardBody className={style.listItem}>
                <CardTitle>{lp.title}</CardTitle>
                <CardSubtitle>
                  {" "}
                  {lp.tags
                    .map((id: number) => tags.find(t => t.id == id).name)
                    .join(", ")}
                </CardSubtitle>
              </CardBody>
            </Card>
          </a>
        ))}
      </CardBody>
    </Card>
  );
}

function noTagsForLevel(tagIds: number[], level: number) {
  // Make use of the fact that IDs of each level share a tens digit.
  return !tagIds.some(i => String(i).startsWith(String(level)));
}
