import React, { useState, useEffect } from "react";
import Ring from "./Ring";
import { tagsByLevel } from "../tags";
import MiddleButton from "./MiddleButton";
import WheelPointer from "./WheelPointer";
import styles from "./wheel.css"

const colors = ["#104F5B", "#3FAA9A", "#6FA066", "#673093"];

interface WheelProps {
  setSelectedTags: (tags: Tag[]) => void;
}

export default function Wheel({ setSelectedTags }: WheelProps) {
  const [selectedTagPerLevel, setSelectedTagPerLevel] = useState<Tag[]>([
    null,
    null,
    null,
    null
  ]);

  useEffect(() => {
    setSelectedTags(selectedTagPerLevel.filter(t => t != null));
  }, [selectedTagPerLevel]);

  return (
    <div className={styles.wheelContainer}>
      <WheelPointer />
      <svg width="100%" height="100%" viewBox="0 0 28 28" className="donut">
        {tagsByLevel
          .slice()
          .reverse()
          .map((tags, i) => (
            <Ring
              key={`ring-${i}`}
              tags={tags}
              r={12 - 3 * i}
              color={colors[i]}
              handleSelectTag={t =>
                t &&
                setSelectedTagPerLevel(tags => {
                  const newTags = tags.slice();
                  newTags[t.level - 1] = t;
                  return newTags;
                })
              }
            />
          ))}
        <MiddleButton r={1.3} />
      </svg>
    </div>
  );
}
