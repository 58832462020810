import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Home from "./home/Home";
import Browse from "./browse/Browse";
import LessonPlan from "./lesson-plan/LessonPlan";
import TopNav from "./topnav/TopNav";
import styles from "./App.css";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { LessonPlansContext } from "./context";
import { useCopyText } from "./useCopyText";

function App() {
  const [allLessonPlans, setAllLessonPlans] = useState<LessonPlan[]>([]);

  useEffect(() => {
    let isMounted = true;
    const db = getFirestore();
    const lessonPlansCollection = collection(db, "lesson-plans");
    getDocs(lessonPlansCollection).then(lessonPlansSnapshot => {
      if (isMounted) {
        setAllLessonPlans(
          lessonPlansSnapshot.docs.map(d =>
            Object.assign(d.data(), { id: d.id })
          ) as LessonPlan[]
        );
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <LessonPlansContext.Provider value={allLessonPlans}>
      <Router>
        <Container className={styles.container}>
          <TopNav />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/browse">
              <Browse />
            </Route>
            <Route path="/lesson-plan/:slug" component={LessonPlan} />
          </Switch>
        </Container>
      </Router>
    </LessonPlansContext.Provider>
  );
}

function About() {
  const aboutText = useCopyText("about");
  return (
    <div className="my-3" dangerouslySetInnerHTML={{ __html: aboutText }} />
  );
}

export default App;
