// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".xF1EROBeNpbqGDMmAWnxc {\n  background-color: var(--lvl1-dark) !important;\n  color: white;\n}\n\n.HDgyrvrAjGax-d9V-Z6T1 {\n  background-color: #eaeaea;\n}\n\n.wNWHmdyweoE919Xuv4h-6 {\n  background-color: #eaeaea;\n  color: #545454;\n}\n", ""]);
// Exports
exports.locals = {
	"listHeader": "xF1EROBeNpbqGDMmAWnxc",
	"listBody": "HDgyrvrAjGax-d9V-Z6T1",
	"listItem": "wNWHmdyweoE919Xuv4h-6"
};
module.exports = exports;
